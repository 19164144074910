import { t } from "i18next";
import { useEffect, useState, useRef, Dispatch, SetStateAction, useContext, useCallback } from 'react';
import { Button, Card, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { Api } from "services/api";
import { TypeChoices } from "components/FileType";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BsTrashFill, BsYoutube } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import DataContext from "context/DataContext";
import IAssistant from "interfaces/IAssistant";
import FilterText from "components/filters/FilterText";
import { FaArrowAltCircleRight, FaChevronCircleRight } from "react-icons/fa";


interface FileUploadProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>
    getList?: () => void;
    onHide?: () => void;
    assistantObject?: IAssistant;
}

const MultipleFileUpload = ({ show, setShow, getList, onHide, assistantObject }: FileUploadProps) => {
    const { profile } = useContext(DataContext);
    const [steps, setSteps] = useState(0);
    const [tabKey, setTabKey] = useState<string>('files');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingAssistantList, setLoadingAssistantList] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState<number[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<
        { file: File; language?: string; customName?: string; type: string }[]
    >([]);
    const [selectedYtVideos, setSelectedYtVideos] = useState<
        { url: string; language?: string; }[]
    >([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const youtubeInputRef = useRef<HTMLInputElement>(null);
    const [assistants, setAssistants] = useState<IAssistant[]>([]);
    const [filteredAssistants, setFilteredAssistants] = useState<IAssistant[]>([]);
    const [filters, setFilters] = useState<any>({});

    const getAssistantList = () => {
        if (profile.id) {
            try {
                setLoadingAssistantList(true);
                Api.get<IAssistant[]>('assistant/assistants-list').then((response) => {
                    setAssistants(response);
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAssistantList(false);
                });
            } catch (e) {
                console.error(e)
            }
        }
    };

    const addAssistant = useCallback((assistant_id: number) => {
        setSelectedAssistant((prevSelected) => [...prevSelected, assistant_id]);
    }, []);

    useEffect(getAssistantList, [profile.id]);

    useEffect(() => {
        if (assistantObject) {
            addAssistant(assistantObject.id);
        }
    }, [addAssistant, assistantObject]);

    useEffect(() => {
        const filtered = assistants.filter((assistant) => {
            let result = true;

            if (typeof filters.name !== 'undefined' && filters.name.length && assistant.name.toLowerCase().indexOf(filters.name.toLowerCase()) === -1) {
                result = false;
            }

            return result;
        });

        setFilteredAssistants(filtered);
    }, [assistants, filters]);

    const removeAssistant = (assistant_id: number) => {
        setSelectedAssistant(selectedAssistant.filter((id) => id !== assistant_id));
    };

    const hasAssistant = (assistant_id: number) => {
        return selectedAssistant.includes(assistant_id);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const acceptedExtensions = ['.pdf', '.docx', '.xlsx', '.html', '.md', '.mp4', '.m4a'];
            const newFiles = Array.from(event.target.files).filter(file => {
                const fileExtension = file.name.split('.').pop();
                return acceptedExtensions.includes(`.${fileExtension}`);
            }).map(function (file) {
                let type = 'document';
                if (file.type === 'video/mp4') {
                    type = 'video';
                } else if (file.type === 'audio/m4a' || file.type === 'audio/x-m4a') {
                    type = 'audio';
                }
                return {
                    file,
                    customName: file.name.replace(/\.[^/.]+$/, ""),
                    type,
                    language: type !== 'document' ? 'hu' : ''
                }
            });
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
            fileInputRef.current!.value = '';
        }
    };

    const handleCustomNameChange = (index: number, newName: string) => {
        setSelectedFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].customName = newName;
            return updatedFiles;
        });
    };

    const handleLanguageChange = (index: number, language: string) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles[index].language = language;
        setSelectedFiles(updatedFiles);
    };

    const removeFile = (fileToRemove: File) => {
        setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile.file !== fileToRemove));
    };

    const handleCloseUploadModal = () => {
        setSelectedFiles([]);
        setShow(false);
        setFilteredAssistants([]);
        setSelectedAssistant([]);
        setSelectedYtVideos([]);
        setSteps(0);
        setTabKey('files');
    }

    const nextStep = () => {
        setSteps(1);
        setTabKey('assistants');
    }

    const addYtVideo = () => {
        const url = youtubeInputRef.current!.value;
        if (url && url.includes('youtube.com')) {
            setSelectedYtVideos([...selectedYtVideos, { url }]);
        }else{
            toast.error(t('file.multipleFileUploadModal.invalidUrl'));
        }
        youtubeInputRef.current!.value = '';
    }

    const handleRemoveYtVideo = (index: number) => {
        setSelectedYtVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
    };

    const handleYtLanguageChange = (index: number, language: string) => {
        setSelectedYtVideos(prevVideos => {
            const newVideos = [...prevVideos];
            newVideos[index] = { ...newVideos[index], language };
            return newVideos;
        });
    };

    const handleUploadFiles = async () => {
        if (selectedFiles.length === 0) {
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((fileData, index) => {
            formData.append('file_name', fileData.file);
        });
        formData.append('custom_names', JSON.stringify(selectedFiles.map(fileData => fileData.customName)));
        formData.append('languages', JSON.stringify(selectedFiles.map(fileData => fileData.language)));
        formData.append('types', JSON.stringify(selectedFiles.map(fileData => fileData.type)));
        formData.append('assistants', JSON.stringify(selectedAssistant));
        formData.append('yt_videos', JSON.stringify(selectedYtVideos));
        formData.append('platform', 'web');

        //console.log(formData.getAll('languages'));
        //console.log(formData.getAll('yt_videos'));
        //console.log(formData.getAll('assistants'));

        setIsLoading(true);
        // API call
        Api.raw('file/multiple-upload', {
            method: 'POST',
            body: formData,
            headers: {},
        }).then((response: any) => {
            if (response.status === 200) {
                toast.success(t('file.multipleFileUploadModal.success'));
                handleCloseUploadModal();
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                if (getList) {
                    getList();
                }
            } else {
                toast.error(t('file.multipleFileUploadModal.error'));
            }
        }).catch((e) => {
            toast.error(t("file.multipleFileUploadModal.error"));
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return <>
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={() => handleCloseUploadModal()}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("file.multipleFileUploadModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="files">
                    <Tabs
                        activeKey={tabKey}
                        onSelect={(k) => k && setTabKey(k)}
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="files" title={t("file.multipleFileUploadModal.files")}>
                            <div className="selected-files mb-3">
                                {selectedFiles.length > 0 && <Card className="shadow-sm mb-3">
                                    <Card.Body className="px-3 py-2">
                                        <Card.Title>{t("file.multipleFileUploadModal.selectedFiles")}</Card.Title>
                                        {selectedFiles.map((fileObj, index) => {
                                            const isMediaFile = fileObj.file.type === 'video/mp4' || fileObj.file.type === 'audio/m4a' || fileObj.file.type === 'audio/x-m4a';
                                            //const fileNameWithoutExtension = fileObj.file.name.replace(/\.[^/.]+$/, "");
                                            let fileIcon = null;
                                            switch (fileObj.file.type) {
                                                case 'video/mp4':
                                                    fileIcon = TypeChoices['video'];
                                                    break;
                                                case 'audio/m4a':
                                                case 'audio/x-m4a':
                                                    fileIcon = TypeChoices['audio'];
                                                    break;
                                                default:
                                                    fileIcon = TypeChoices['document'];
                                                    break;
                                            }
                                            return (
                                                <div key={index} className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        {fileIcon}
                                                    </span>
                                                    <input type="text"
                                                        className="form-control"
                                                        value={fileObj.customName}
                                                        onChange={e => handleCustomNameChange(index, e.target.value)}
                                                    />
                                                    {isMediaFile && (
                                                        <select className="form-select audio-language-dropdown" onChange={(e) => handleLanguageChange(index, e.target.value)}>
                                                            <option value='hu'>{t('file.multipleFileUploadModal.lang.hu')}</option>
                                                            <option value='en'>{t('file.multipleFileUploadModal.lang.en')}</option>
                                                        </select>
                                                    )}
                                                    <button className="btn btn-outline-danger" type="button" onClick={() => removeFile(fileObj.file)}>
                                                        {t("file.multipleFileUploadModal.delete")}
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </Card.Body>
                                </Card>}
                                {selectedYtVideos.length > 0 && <Card className="shadow-sm">
                                    <Card.Body className="px-3 py-2">
                                        <Card.Title>{t("file.multipleFileUploadModal.selectedYouTube")}</Card.Title>
                                        {selectedYtVideos.map((yt, index) => {
                                            return (
                                                <div key={index} className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <BsYoutube size={24} />
                                                    </span>
                                                    <input type="text"
                                                        className="form-control"
                                                        value={yt.url}
                                                        readOnly
                                                    />
                                                    <select className="form-select audio-language-dropdown" onChange={(e) => handleYtLanguageChange(index, e.target.value)}>
                                                        <option value='hu'>{t('file.multipleFileUploadModal.lang.hu')}</option>
                                                        <option value='en'>{t('file.multipleFileUploadModal.lang.en')}</option>
                                                    </select>
                                                    <button className="btn btn-outline-danger" type="button" onClick={() => handleRemoveYtVideo(index)}>
                                                        {t("file.multipleFileUploadModal.delete")}
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </Card.Body>
                                </Card>}
                            </div>
                            <Card>
                                <Card.Body>
                                    <div>
                                        <input type="file"
                                            className="form-control"
                                            multiple
                                            accept=".pdf,.docx,.xlsx,.html,.md.mp4,.m4a"
                                            onChange={handleFileChange}
                                            ref={fileInputRef} />
                                    </div>
                                    <div>
                                        <div className="input-group mt-3">
                                            <input type="text"
                                                className="form-control"
                                                placeholder={t("file.multipleFileUploadModal.ytVideoUrl")}
                                                ref={youtubeInputRef} />
                                            <button className="btn btn-outline-secondary" type="button" onClick={addYtVideo}>
                                                {t("file.multipleFileUploadModal.add")}
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Tab>
                        <Tab eventKey="assistants" title={t("file.multipleFileUploadModal.assistants")} disabled={steps > 0 ? false : true}>
                            <Table responsive="sm" hover className="align-middle" id="assistant-list">
                                <thead>
                                    <tr>
                                        <th>
                                            {t("file.multipleFileUploadModal.table.avatar")}
                                        </th>
                                        <th>
                                            {t("file.multipleFileUploadModal.table.title")}
                                            <FilterText filters={filters} setFilters={setFilters} field="name"
                                                fieldLabel="file.multipleFileUploadModal.table.title"></FilterText>
                                        </th>
                                        <th className="text-end">
                                            {t("group.operations")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredAssistants.length === 0 && <tr>
                                        <td colSpan={4}
                                            className="text-center">{t('assistants.document.noPermission')}</td>
                                    </tr>}
                                    {filteredAssistants.map((assistant, index) => <tr key={index}>
                                        <td>
                                            <div className="assistant-profile-img">
                                                {assistant.profile_image.length ? (<img src={assistant.profile_image} className="rounded-circle" alt="" />) : (<img src="images/default-profile.png" className="rounded-circle" alt="" />)}
                                            </div>
                                        </td>
                                        <td>
                                            {assistant.name}
                                        </td>
                                        <td className="text-end">
                                            {!isLoading && (hasAssistant(assistant.id) ?
                                                <Button onClick={() => removeAssistant(assistant.id)} variant="outline-danger">
                                                    <BsTrashFill />
                                                </Button> :
                                                <Button onClick={() => addAssistant(assistant.id)} variant="outline-secondary">
                                                    <IoMdAdd />
                                                </Button>
                                            )}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer id="upload-modal-footer" className="justify-content-between">
                <Button variant="secondary" onClick={(e) => handleCloseUploadModal()}>
                    {t('file.multipleFileUploadModal.cancel')}
                </Button>
                {steps > 0 && selectedFiles.length > 0 && (
                    <Button variant="primary" className="mt-3 btn btn-success" onClick={() => handleUploadFiles()} disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {' ' + t('file.multipleFileUploadModal.upload')}...
                            </>
                        ) : (
                            <>{t('file.multipleFileUploadModal.upload')}</>
                        )}
                    </Button>
                )}
                {steps === 0 && (selectedFiles.length > 0 || selectedYtVideos.length > 0) && (
                    <Button variant="primary" className="mt-3 btn btn-success" onClick={nextStep}>
                        <div className="d-inline-flex justify-content-between align-items-center">
                            {t("file.multipleFileUploadModal.next")}
                            <FaChevronCircleRight size={18} className="ms-2"/>
                        </div>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    </>

}

export default MultipleFileUpload;