import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Button, DropdownButton, Stack, Table, Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { t } from "i18next";
import { FaFileCode, FaFileUpload, FaPlay } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";

import { usePermissions } from "hooks/usePermissions";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import FilterSelect from "components/filters/FilterSelect";
import FilterText from "components/filters/FilterText";
import FilterDate from "components/filters/FilterDate";

import IFile from "interfaces/IFile";
import { Api } from "services/api";
import { bytesToSize, shortenFileName } from "services/utils";

import AddDocument from "./components/AddDocument";
import AddVideo from "./components/AddVideo";
import AddAudio from "./components/AddAudio";
import FileType, { TypeChoices } from "../../components/FileType";
import StatusBadge from "./components/StatusBadge";
import ContentModal from "./components/ContentModal";
import TopicsModal from "./components/TopicsModal";
import DeleteModal from "./components/DeleteModal";
import FileAssistants from "./components/FileAssistants";
import Dropdown from 'react-bootstrap/Dropdown';
import { HiDotsVertical } from "react-icons/hi";

import './FilePage.css';
import Rights from "./components/Rights";
import MultipleFileUpload from './components/MultipleFileUpload';

const FilePage = () => {
    const { checkPermissions } = usePermissions();
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<IFile[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<IFile[]>([]);
    const [filters, setFilters] = useState<any>({});
    const [showUploadModal, setShowUploadModal] = useState(false);

    const getList = async () => {
        setIsLoading(true);
        const response = await Api.get<IFile[]>('file/').then(response => {
            return response;
        }).catch(e => {
            console.error(e);
            return [];
        }).finally(() => {
            setIsLoading(false);
        });
        setFiles(response);
    }

    useEffect(() => {
        checkPermissions([
            'file.view_file',
            'file.view_document',
            'file.view_video',
            'file.view_audio',
            'file.add_file',
            'file.add_document',
            'file.add_video',
            'file.add_audio',
            'file.change_file',
            'file.delete_file',
            'file.delete_document',
            'file.delete_video',
            'file.delete_audio'
        ]);

        void getList();
    }, []);

    useEffect(() => {
        const needUpdate = files.filter((file) => file.status !== 'completed' && file.status !== 'failed');
        if (needUpdate.length) {
            setTimeout(getList, 2000);
        }
    }, [files]);

    useEffect(() => {
        const filtered = files.filter((file) => {
            let result = true;

            // Típus szerinti szűrés
            if (typeof filters.type !== 'undefined' && filters.type.length && !filters.type.includes(file.type)) {
                result = false;
            }

            // Cím szerinti szűrés
            if (typeof filters.title !== 'undefined' && filters.title.length && file.title.toLowerCase().indexOf(filters.title.toLowerCase()) === -1) {
                result = false;
            }

            // Dátum szerinti szűrés
            if (typeof filters.date !== 'undefined' && filters.date.length === 2) {
                const fileDate = new Date(file.date); // A file.date string konvertálása Date objektummá
                const startDate = filters.date[0] ? new Date(filters.date[0]) : null; // Kezdő dátum konvertálása Date objektummá
                let endDate = filters.date[1] ? new Date(filters.date[1]) : null;   // Vég dátum konvertálása Date objektummá

                // Az endDate időpontját a nap végére állítjuk (23:59:59)
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                // Csak akkor szűrjük meg, ha mind a kezdő, mind a vég dátum meg van adva
                if (startDate && endDate) {
                    if (fileDate < startDate || fileDate > endDate) {
                        result = false;  // Ha a fileDate kívül esik az intervallumon, akkor false
                    }
                }
            }

            return result;
        });

        setFilteredFiles(filtered);
    }, [filters, files]);

    return (
        <PermissionCheck permission={['file.view_file', 'file.view_document', 'file.view_video', 'file.view_audio']}>

            <div className="video-add-buttons d-flex justify-content-end mt-2">
                {/* <AddAudio getList={getList}/> */}
                {/* <AddVideo getList={getList}/> */}
                {/* <AddDocument getList={getList}/> */}
                {isLoading && (<div className="d-inline-flex align-items-center justify-content-start me-auto">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-2">{t("file.data_loading")}</span>
                </div>)}
                <Button onClick={() => setShowUploadModal(true)}
                    className="mb-3 px-2 d-inline-flex align-items-center justify-content-between">
                    <FaFileUpload size={18} />
                    <span className="ms-2">{t("file.multipleFileUploadModal.title")}</span>
                </Button>
            </div>
            <div className="card shadow-sm mb-3 px-3 py-2 overflow-x-auto">
                <Table responsive="sm" hover className="align-middle">
                    <thead>
                        <tr>
                            <th>
                                {t("file.table.type")}
                                <FilterSelect field="type" fieldLabel={t("file.table.type")} choices={TypeChoices}
                                    filters={filters} setFilters={setFilters} />
                            </th>
                            <th>
                                {t("file.table.date")}
                                <FilterDate filters={filters} setFilters={setFilters} field="date"
                                    fieldLabel={t("file.table.date")}></FilterDate>
                            </th>
                            <th>
                                {t("file.table.name")}
                                <FilterText filters={filters} setFilters={setFilters} field="title"
                                    fieldLabel={t("file.table.name")}></FilterText>
                            </th>
                            <th className="text-center">
                                {t("file.table.status")}
                            </th>
                            <th className="text-center">
                                {t("file.table.size")}
                            </th>
                            <th className="text-end">
                                {t("file.table.operations")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && filteredFiles.length === 0 && <tr>
                            <td colSpan={6} className="text-center">{t('file.table.empty')}</td>
                        </tr>}
                        {filteredFiles.length !== 0 && filteredFiles.map((file: IFile) => (
                            <tr key={file.id}>
                                <td className="text-center"><FileType file={file} /></td>
                                <td className="text-nowrap">{file.date}</td>
                                <td><span title={file.title}>{shortenFileName(file.title || '')}</span></td>
                                <td className="text-center"><StatusBadge file={file} /></td>
                                <td className="text-center">{bytesToSize(file.total_media_size)}</td>
                                <td className="text-end">
                                    <DropdownButton
                                        align="end"
                                        title={<HiDotsVertical size={18} />}
                                        id="dropdown-menu-align-end"
                                        className='file-list-dropdown'
                                        variant="outline-secondary"
                                        size="sm"
                                    >
                                        {file.type !== 'document' && <Dropdown.Item href={file.type === 'youtube' ? file.url! : file.playUrl!}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <FaPlay size={18} />
                                                <div className="ms-2">{t('audio.playAudio')}</div>
                                            </div>
                                        </Dropdown.Item>}

                                        {file.content && <Dropdown.Item href={file.pdfUrl!} target="_blank">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <FaFilePdf size={18} />
                                                <div className="ms-2">{t('file.pdf_export')}</div>
                                            </div>
                                        </Dropdown.Item>}

                                        {file.content && file.type !== 'document' && <Dropdown.Item href={file.pdfSrtUrl!} target="_blank">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <FaFileCode size={18} />
                                                <div className="ms-2">{t('file.pdf_srt_export')}</div>
                                            </div>
                                        </Dropdown.Item>}

                                        {file.type === 'document' && <Dropdown.Item href={file.documentUrl!} target="_blank">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <FaFileCode size={18} />
                                                <div className="ms-2">{t('file.documentUrl')}</div>
                                            </div>
                                        </Dropdown.Item>}

                                        {file.type === 'document' && <TopicsModal file={file} type="summary" />}

                                        <Rights file={file} />

                                        <FileAssistants file={file} />

                                        <ContentModal file={file} getList={getList} />

                                        <TopicsModal file={file} type="topics" />

                                        {file.type !== 'document' && <TopicsModal file={file} type="summary" />}

                                        <Dropdown.Divider />

                                        <DeleteModal file={file} getList={getList} />

                                    </DropdownButton>

                                    {/* <Stack direction="horizontal" gap={2} className="justify-content-end mb-2">
                                        {file.type !== 'document' &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">
                                                    <div>{t('audio.playAudio')}</div>
                                                </Tooltip>}
                                            >
                                                <Link to={file.type === 'youtube' ? file.url! : file.playUrl!}
                                                    target="_blank">
                                                    <Button variant="outline-secondary" size="sm">
                                                        <FaPlay size={18} />
                                                    </Button>
                                                </Link>

                                            </OverlayTrigger>
                                        }

                                        {file.content &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">
                                                    <div>{t('file.pdf_export')}</div>
                                                </Tooltip>}
                                            >
                                                <Link to={file.pdfUrl!} target="_blank">
                                                    <Button variant="outline-secondary" size="sm">
                                                        <FaFilePdf size={18} />
                                                    </Button>
                                                </Link>
                                            </OverlayTrigger>
                                        }
                                        {file.content && file.type !== 'document' &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">
                                                    <div>{t('file.pdf_srt_export')}</div>
                                                </Tooltip>}
                                            >
                                                <Link to={file.pdfSrtUrl!} target="_blank">
                                                    <Button variant="outline-secondary" size="sm">
                                                        <FaFileCode size={18} />
                                                    </Button>
                                                </Link>
                                            </OverlayTrigger>
                                        }
                                        {file.type === 'document' &&
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2">
                                                    <div>{t('file.documentUrl')}</div>
                                                </Tooltip>}
                                            >
                                                <Link to={file.documentUrl!} target="_blank">
                                                    <Button variant="outline-secondary" size="sm">
                                                        <IoMdDownload size={18} />
                                                    </Button>
                                                </Link>
                                            </OverlayTrigger>
                                        }
                                        {file.type === 'document' &&
                                            <TopicsModal file={file} type="summary" />
                                        }
                                        <Rights file={file} />
                                        <FileAssistants file={file} />
                                        <DeleteModal file={file} getList={getList} />
                                    </Stack>
                                    <Stack direction="horizontal" gap={2} className="justify-content-end">
                                        <ContentModal file={file} getList={getList} />
                                        <TopicsModal file={file} type="topics" />
                                        {file.type !== 'document' &&
                                            <TopicsModal file={file} type="summary" />
                                        }
                                    </Stack> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <MultipleFileUpload getList={getList} show={showUploadModal} setShow={setShowUploadModal} />
        </PermissionCheck>
    );
};

export default FilePage;