import React, { useState } from 'react';
import { Button, Dropdown, Modal, Tooltip } from 'react-bootstrap';
import { t } from 'i18next';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import IFile from 'interfaces/IFile';
import { MdForum } from "react-icons/md";

interface FileAssistantsModalProps {
    file: IFile
}

const FileAssistants = ({ file }: FileAssistantsModalProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            {file.assistants &&
                <Dropdown.Item as="button" onClick={() => {
                    setShowModal(true)
                }
                }>
                    <div className="d-flex justify-content-between align-items-center">
                        <MdForum size={18} />
                        <div className="ms-2">{t('file.contentModal.assistants')}</div>
                    </div>
                </Dropdown.Item>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("file.contentModal.assistants")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-group">
                        {file.assistants && file.assistants.map((assistant, index) => {
                            return (
                                <li key={index} className='list-group-item'>
                                    <b>{assistant.name}</b>
                                </li>
                            )
                        })}
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FileAssistants;