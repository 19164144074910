import { t } from "i18next";
import { useEffect, useState, useRef } from 'react';
import { Accordion, AccordionHeader, Badge, Button, Card, Modal, Spinner, Table } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import { IoDocumentsOutline } from "react-icons/io5";

import { TypeChoices } from "components/FileType";
import FilterDate from "components/filters/FilterDate";
import FilterSelect from "components/filters/FilterSelect";
import FilterText from "components/filters/FilterText";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import IAssistant from "interfaces/IAssistant";
import { IAssistantFile } from "interfaces/IAssistantFile";
import toast from "react-hot-toast";
import { Api } from "services/api";
import { shortenFileName } from "../../../services/utils";
import AccordionCustomBodyToggle from "./AccordionCustomBodyToggle";
import AccordionCustomToggle from "./AccordionCustomToggle";
import MultipleFileUpload from "pages/file/components/MultipleFileUpload";
import { FaDownload, FaFileUpload } from "react-icons/fa";
//import { se } from "date-fns/locale";

interface DocumentsProps {
    assistant: IAssistant;
}

const StateChoices = {
    'true': <BsTrashFill size={24} />,
    'false': <IoMdAdd size={24} />,
}

const hasFile = (file: IAssistantFile, assistantFiles: IAssistantFile[]) => {
    const filtered = assistantFiles.filter((filteredFile) => {
        // eslint-disable-next-line eqeqeq
        return filteredFile.file_id == file.file_id;
    });
    return filtered.length > 0;
}

const Documents = ({ assistant }: DocumentsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const [files, setFiles] = useState<IAssistantFile[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<IAssistantFile[]>([]);
    const [assistantObject, setAssistantObject] = useState<IAssistant>(assistant);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const attachFile = async (file: IAssistantFile) => {
        setIsLoading(true);
        await Api.post(`assistant/${assistantObject.id}/attach`, { file_id: file.id })
        loadAssistant().then(() => {
            void loadFiles();
        });
        setIsLoading(false);
        toast.success(t('assistants.document_added'));
    };

    const detachFile = async (file: IAssistantFile) => {
        setIsLoading(true);
        try {
            await Api.post(`assistant/${assistantObject.id}/detach`, { file_id: file.file_id })
            loadAssistant().then(() => {
                void loadFiles();
            });
            toast.success(t('assistants.document_remove_success'));
        } catch (e) {
            console.error(e);
            toast.error(t('assistants.document_remove_error'));
        } finally {
            setIsLoading(false);
        }
    };

    const loadFiles = async () => {
        const response = await Api.get<IAssistantFile[]>('assistant/files');
        setFiles(response);
    }

    const loadAssistant = async () => {
        const response = await Api.get<IAssistant>('assistant/assistant/' + assistant.id);
        setAssistantObject(response);
    }

    useEffect(() => {
        if (showModal) {
            setIsLoading(true);
            loadAssistant().then(() => {
                void loadFiles();
            }).catch((e) => {
                console.error(e)
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [showModal]);

    useEffect(() => {
        const filtered = files.filter((file) => {
            let result = true;

            if (typeof filters.title !== 'undefined' && filters.title.length && file.title.toLowerCase().indexOf(filters.title.toLowerCase()) === -1) {
                result = false;
            }

            // Típus szerinti szűrés
            if (typeof filters.type !== 'undefined' && filters.type.length && !filters.type.includes(file.type)) {
                result = false;
            }

            // Dátum szerinti szűrés
            if (typeof filters.created_at !== 'undefined' && filters.created_at.length === 2) {
                const fileDate = new Date(file.created_at); // A file.date string konvertálása Date objektummá
                const startDate = filters.created_at[0] ? new Date(filters.created_at[0]) : null; // Kezdő dátum konvertálása Date objektummá
                let endDate = filters.created_at[1] ? new Date(filters.created_at[1]) : null;   // Vég dátum konvertálása Date objektummá

                // Az endDate időpontját a nap végére állítjuk (23:59:59)
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                // Csak akkor szűrjük meg, ha mind a kezdő, mind a vég dátum meg van adva
                if (startDate && endDate) {
                    if (fileDate < startDate || fileDate > endDate) {
                        result = false;  // Ha a fileDate kívül esik az intervallumon, akkor false
                    }
                }
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasPerm = hasFile(file, assistant.files);
                if (
                    (hasPerm && !filters.operations.includes('true')) ||
                    (!hasPerm && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredFiles(filtered);
    }, [files, filters]);

    const handleShowUploadModal = () => {
        setShowModal(false);
        setShowUploadModal(true);
    }


    return (
        <PermissionCheck permission={['assistant.view_assistantfiles']}>
            <PermissionCheck permission={['file.view_file']}>
                <Button variant="outline-secondary" size="sm" onClick={e => {
                    setShowModal(true)
                }}>
                    <div className="d-flex justify-content-start align-items-center gap-2">
                        <IoDocumentsOutline size={18} />
                        <span>{t("assistants.table.add_documents")}</span>
                        <Badge pill bg="secondary">{assistantObject.files?.length ?? 0}</Badge>
                    </div>
                </Button>

                <MultipleFileUpload
                    show={showUploadModal}
                    setShow={setShowUploadModal}
                    assistantObject={assistantObject}
                />

                <Modal
                    show={showModal}
                    className="full-height-modal"
                    onHide={() => setShowModal(false)} centered size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t("assistants.document_assistant_title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 px-3 py-2 scroll-container">
                            <div className="scroll-inner">
                                <Accordion defaultActiveKey="-1">
                                    <div className="text-center">
                                        <Button onClick={handleShowUploadModal}
                                            className="mb-3 d-inline-flex align-items-center justify-content-center">
                                            <span className="ms-2"><FaFileUpload size={24} /> {t("file.multipleFileUploadModal.title")}</span>
                                        </Button>
                                    </div>
                                    <Table responsive="sm" hover className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {t("assistants.document.table.created_at")}
                                                    <FilterDate filters={filters} setFilters={setFilters} field="created_at"
                                                        fieldLabel={t('assistants.document.table.created_at')}></FilterDate>
                                                </th>
                                                <th>
                                                    {t("assistants.document.table.title")}
                                                    <FilterText filters={filters} setFilters={setFilters} field="title"
                                                        fieldLabel="assistants.document.table.title"></FilterText>
                                                </th>
                                                <th>
                                                    {t("assistants.document.table.type")}
                                                    <FilterSelect field="type" fieldLabel={t("group.operations")}
                                                        choices={TypeChoices} filters={filters}
                                                        setFilters={setFilters} />
                                                </th>
                                                <th className="text-end">
                                                    {t("group.operations")}
                                                    <FilterSelect field="operations" fieldLabel={t("group.operations")}
                                                        choices={StateChoices} filters={filters}
                                                        setFilters={setFilters} />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && filteredFiles.length === 0 && <tr>
                                                <td colSpan={4}
                                                    className="text-center">{t('assistants.document.noPermission')}</td>
                                            </tr>}
                                            {isLoading && (
                                                <tr>
                                                    <td colSpan={4} className="text-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {filteredFiles.map((file, index) => <tr key={file.id}>
                                                <td>
                                                    {file.created_at}
                                                </td>
                                                <td>
                                                    <AccordionCustomToggle eventKey={index.toString()}>
                                                        <span title={file.title}>{shortenFileName(file.title)}</span>
                                                        <Accordion.Item eventKey={index.toString()}
                                                            className='border-0 p-0' style={{ "maxWidth": "360px" }}>
                                                            <Accordion.Body className='p-0'>
                                                                <AccordionCustomBodyToggle
                                                                    eventKey={index.toString()}
                                                                >
                                                                    <Card
                                                                        className='shadow-sm mt-2 accordian-card'>
                                                                        <Card.Header className="text-wrap">{file.file_name}</Card.Header>
                                                                        <Card.Body className='py-2'>
                                                                            <pre
                                                                                style={{ 'whiteSpace': 'pre-wrap' }}>{file.description}</pre>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </AccordionCustomBodyToggle>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </AccordionCustomToggle>
                                                </td>
                                                <td>
                                                    {TypeChoices[file.type]}
                                                </td>
                                                <td className="text-end">
                                                    {file.type === 'document' && <Button className="me-2" as="a" href={file.documentUrl ?? undefined} variant="outline-secondary">
                                                        <FaDownload />
                                                    </Button>}
                                                    {isLoading && <Spinner animation="border" size="sm" variant="primary" className="d-inline-block" />}
                                                    {!isLoading && (hasFile(file, assistantObject.files) ?
                                                        <Button onClick={() => detachFile(file)} variant="outline-danger">
                                                            <BsTrashFill />
                                                        </Button> :
                                                        <Button onClick={() => attachFile(file)} variant="outline-secondary">
                                                            <IoMdAdd />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Accordion>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </PermissionCheck>
        </PermissionCheck>
    );
};

export default Documents;