import React, { useState } from 'react';
import IFile from "../../../interfaces/IFile";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Button, Dropdown, Modal, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import { MdSummarize, MdTopic } from "react-icons/md";
import { RiMailSendFill } from "react-icons/ri";

interface TopicsModalProps {
    file: IFile,
    type: 'summary' | 'topics'
};

const TopicsModal = ({ file, type }: TopicsModalProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    if (type == 'topics' && file.type == 'document') {
        return <></>
    }

    return (
        <>
            {file[type] &&
                <Dropdown.Item as="button" onClick={() => {
                    setShowModal(true)
                }
                }>
                    <div className='d-flex justify-content-between align-items-center'>
                        {type == 'summary' && <MdSummarize size={18} />}
                        {type == 'topics' && <MdTopic size={18} />}
                        <div className="ms-2">{t('file.contentModal.' + type)}</div>
                    </div>
                </Dropdown.Item >
            }

            <Modal size="lg"
                show={showModal}
                onHide={() => { setShowModal(false) }}
                className="full-height-modal"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("audio.topicsDownload")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mb-3">{file.title}</h5>
                    <a className="btn btn-sm btn-primary mb-4"
                        href={`mailto:?subject=${t('audio.title')} - ${file.title}&body=${encodeURIComponent(file[type] as string)}`}
                        rel="noreferrer">
                        <RiMailSendFill size={14} /><span className="ps-1">{t("audio.sendMail")}</span>
                    </a>
                    <div className="scroll-container">
                        <pre className="scroll-inner" style={{ 'whiteSpace': 'pre-wrap' }}>{file[type]}</pre>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TopicsModal;